import React, { useState } from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import MobileNav from "./mobile-nav";

const NavLink = styled(AnchorLink)`
  color: #1a4428;
  font-family: "Reem Kufi";
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  text-shadow: 1px 1px 3px #c2c2c266;
  &.current-page {
    border-bottom: 2px solid #1a4428;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const MenuBar = styled("div")`
  width: 40px;
  height: 5px;
  background-color: #1a4428;
  border-radius: 5px;
`;

const Header = () => {
  const logo = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "bow_logo_symbol.png" }) {
        image: childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const [mobileNavHeight, setMobileNavHeight] = useState("0px");

  return (
    <header
      css={css`
        position: fixed;
        width: 100%;
        top: 0;
        background: linear-gradient(0.25turn, #ebf8e1, #f1a948);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        z-index: 100;
        min-height: 72px;
        @media (max-width: 1024px) {
          height: 60px;
          min-height: 60px;
          padding: 0px 5px;
        }
      `}
    >
      <div
        css={css`
          width: 125px;
          @media (max-width: 1024px) {
            height: 50px;
          }
        `}
      >
        <AnchorLink to="/">
          <Image
            fadeIn={false}
            loading="eager"
            fixed={logo.file.image.fixed}
            alt="BOW Renewables Logo"
            css={css`
              @media (max-width: 1024px) {
                width: 100%;
                height: auto;
              }
            `}
          />
        </AnchorLink>
      </div>
      {/*  site name --- navigation  */}
      <nav
        css={css`
          width: 50%;
          max-width: 700px;
          min-width: 600px;
          margin-top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 1300px) {
            max-width: 850px;
            width: 65%;
          }
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <NavLink to="/about" title="WHY BOW" activeClassName="current-page" />
        <NavLink
          to="/gosolar"
          title="GO SOLAR"
          activeClassName="current-page"
        />

        <NavLink
          to="/consulting"
          title="CONSULTING"
          activeClassName="current-page"
        />
        <NavLink
          to="/customsolutions"
          title="CUSTOM SOLUTIONS"
          activeClassName="current-page"
        />
        <NavLink
          to="/projects"
          title="PROJECTS"
          activeClassName="current-page"
        />
      </nav>
      <nav
        css={css`
          margin-top: 0;
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <NavLink
          to="/about#contact"
          title="CONTACT"
          activeClassName="current-page"
          stripHash
        />
      </nav>
      <button
        name="Mobile Navigation"
        className="mobile-nav"
        css={css`
          display: none;
          @media (max-width: 1024px) {
            border: none;
            margin: none;
            padding: none;
            background-color: none;
            background: none;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 40px;
            width: 60px;
          }
        `}
        onClick={() => {
          setMobileNavHeight(
            mobileNavHeight === "0px" ? "Calc(100vh - 122px)" : "0px"
          );
        }}
        onKeyDown={() => {
          setMobileNavHeight(
            mobileNavHeight === "0px" ? "Calc(100vh - 122px)" : "0px"
          );
        }}
      >
        <MenuBar></MenuBar>
        <MenuBar></MenuBar>
        <MenuBar></MenuBar>
      </button>
      <MobileNav height={mobileNavHeight} setHeight={setMobileNavHeight} />
    </header>
  );
};

export default Header;

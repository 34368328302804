import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const FullPageOrangeSection = styled("section")`
  position: relative;
  height: fit-content;
  min-height: Calc(100vh - 72px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f1a948;
  overflow: hidden;
  @media (max-width: 950px) {
    min-height: 500px;
    height: fit-content;
  }
`;

export const WhiteSection = styled("section")`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const FullPageWhiteSection = styled("section")`
  position: relative;
  height: fit-content;
  min-height: Calc(100vh - 72px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @media (max-width: 950px) {
    min-height: 500px;
    height: fit-content;
  }
`;

export const OrangeSection = styled("section")`
  position: relative;
  height: fit-content;
  width: 100%;
  background-color: #f1a948;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 825px) and (orientation: landscape) {
    min-height: 728px;
  }
`;

export const FadeDown = styled("div")`
  position: absolute;
  top: 0;
  width: 100%;
  height: 30vh;
  background-image: ${(props) =>
    `linear-gradient(to bottom, ${props.color}, #ffffff00)`};
`

export const FadeUp = styled("div")`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30vh;
  background-image: ${(props) =>
    `linear-gradient(to top, ${props.color}, #ffffff00)`};
`;

export const BlurbWrapper = styled("div")`
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  min-width: 550px;
  z-index: 5;
  @media (max-width: 768px) {
    width: 90vw;
    min-width: 100px;
  }
`;

export const TextImageWrapper = styled("div")`
  display: flex;
  width: 80%;
  min-width: 700px;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  @media (max-width: 768px) {
    width: 90vw;
    min-width: 100px;
    flex-direction: ${props => props.reverse ? "column-reverse" : "column"};
  }
`;

export const TitleText = styled("h1")`
  margin: 1.5rem auto 1rem auto;
  color: ${(props) => (props.color === "green" ? "#1a4428" : "white")};
  font-size: 1.75rem;
  width: 50%;
  min-width: 500px;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 1.5rem;
  }
  @media (max-width: 950px) {
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    margin: 1rem auto;
    font-size: 1rem;
    width: 90vw;
    min-width: 100px;
  }
  @media (max-width: 425px) {
    /* margin: 1rem auto 0 auto; */
    font-size: 1rem;
    width: 90vw;
    min-width: 100px;
  }
`;

export const SubTitleText = styled("h1")`
  margin: 1.5rem auto 1rem auto;
  color: ${(props) => (props.color === "green" ? "#1a4428" : "white")};
  font-size: 1.5rem;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 1.25rem;
  }
  @media (max-width: 950px) {
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    margin: 0.9rem auto;
    font-size: 1rem;
  }
  @media (max-width: 425px) {
    /* margin: 1rem auto 0 auto; */
    font-size: 0.9rem;
  }
`;

export const BlurbText = styled("h2")`
  font-size: 1.25rem;
  color: ${(props) => (props.color ? props.color : "white")};
  margin-bottom: ${(props) => (props.margin ? "20px" : "")};
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

export const DescriptionWrapper = styled("div")`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1024px) {
    min-width: 250px;
  }
`;

export const PageBreakText = styled("h2")`
  font-size: 1.75rem;
  color: ${(props) => (props.color === "green" ? "#215732" : "white")};
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1rem;
    max-width: 90vw;
  }
`;

export const IconWrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  min-width: 650px;
  @media (max-width: 768px) {
    width: 90vw;
    min-width: 100px;
    flex-direction: column;
    align-items: center;
  }
`;

export const WhiteLinkButton = styled(AnchorLink)`
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  width: fit-content;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 1.5rem auto 1.5rem auto;
  color: #f1a948;
  background-color: white;
  @media (max-width: 768px) {
    font-size: 1rem;
    min-height: 35px;
    max-height: 35px;
    margin: 1rem auto 1rem auto;
  }
`;

export const GreenLinkButton = styled(AnchorLink)`
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  width: fit-content;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 1.5rem auto 1.5rem auto;
  color: white;
  background-color: #215732;
  @media (max-width: 768px) {
    font-size: 1rem;
    min-height: 35px;
    max-height: 35px;
    margin: 1rem auto 1rem auto;
  }
`;

export const QuickLink = styled(AnchorLink)`
  font-family: Montserrat;
  font-size: 0.85rem;
  text-decoration: none;
  color: white;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const BlurbLink = styled(AnchorLink)`
  font-size: 1.25rem;
  color: ${(props) => (props.color ? props.color : "white")};
  margin-bottom: ${(props) => (props.margin ? "20px" : "")};
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

export const quickLinkCss = css`
  font-family: Montserrat;
  font-size: 0.85rem;
  text-decoration: none;
  color: white;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const GreenBreak = styled("div")`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "25vh")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "250px")};
  background-color: #215732;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
`;

export const OrangeBreak = styled("div")`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "25vh")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "250px")};
  background-color: #f1a948;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #1a4428;
`;

export const Arrow = styled("i")`
  border: solid #215732;
  border-width: 0 20px 20px 0;
  margin-bottom: 10%;
  display: inline-block;
  padding: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  @media (max-width: 1024px) {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 30px;
  }
`;

export const GalleryArrow = styled("i")`
  border: solid #215732;
  border-width: 0 30px 30px 0;
  display: inline-block;
  padding: 30px;
  transform: ${(props) =>
    props.direction === "right" ? "rotate(-45deg)" : "rotate(135deg)"};
  -webkit-transform: ${(props) =>
    props.direction === "right" ? "rotate(-45deg)" : "rotate(135deg)"};
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1300px) {
    border-width: 0 20px 20px 0;
    margin: ${(props) =>
      props.direction === "right" ? "0 10px 0 0" : "0 0 0 10px"};
    padding: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0) rotate(45deg);
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
  }

  40%, 43% {
    transform: translate3d(0, -15px, 0) rotate(45deg);
    -webkit-transform: translate3d(0, -15px, 0) rotate(45deg);
  }

  70% {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    -webikit-transform: translate3d(0, -10px, 0) rotate(45deg);
  }

  90% {
    transform: translate3d(0,-4px,0) rotate(45deg);
    -webkit-transform: translate3d(0,-4px,0) rotate(45deg);
  }
`;

export const DownArrow = styled("i")`
  border: solid white;
  border-width: 0 10px 10px 0;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: ${bounce} 2s ease infinite;
  :hover {
    cursor: pointer;
  }
`;

const rotateToUp = keyframes`
0% {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

100% {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
`;

export const UpArrow = styled("i")`
  border: solid white;
  border-width: 0 10px 10px 0;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: ${rotateToUp} 1s ease 1 forwards;
  :hover {
    cursor: pointer;
  }
`;

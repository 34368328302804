import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";

const Footer = () => {
  const footerImages = useStaticQuery(graphql`
    query {
      icons: allFile(filter: { relativeDirectory: { eq: "footer-icons" } }) {
        nodes {
          sharp: childImageSharp {
            fixed(height: 36, width: 36) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
          id
        }
      }
      logo: file(relativePath: { eq: "bow_logo_symbol.png" }) {
        image: childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const linkMap = {
    instagram: "https://www.instagram.com/bowrenew/",
    linkedin: "https://www.linkedin.com/company/bow-renewables/",
    facebook: "https://www.facebook.com/bowrenew",
    twitter: "https://twitter.com/bowrenew",
  };

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 20px;
          background-color: #215732;
          margin-top: 0;
        `}
      />
      <div
        css={css`
          width: 100%;
          min-height: 100px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background-color: white;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            width: 400px;
            display: flex;
            justify-content: space-around;
            @media (max-width: 950px) {
              width: 300px;
            }
            @media (max-width: 768px) {
              margin: 20px 0;
              width: 90vw;
            }
          `}
        >
          {footerImages.icons.nodes.map((img) => (
            <a
              href={linkMap[`${img.sharp.fixed.originalName.split(".")[0]}`]}
              target="_blank"
              rel="noreferrer"
              key={img.id}
            >
              <Image
                fadeIn={false}
                loading="eager"
                css={css`
                  margin: 0 5px;
                  border-radius: 100%;
                  &:hover {
                    box-shadow: 0px 0px 5px #215732;
                  }
                `}
                fixed={img.sharp.fixed}
                alt={`Link to ${img.sharp.fixed.originalName.split(".")[0]}}`}
              />
            </a>
          ))}
        </div>
        <div
          css={css`
            @media (max-width: 950px) {
              display: none;
            }
          `}
        >
          <Image
            fixed={footerImages.logo.image.fixed}
            alt="BOW Renewables Logo"
          />
        </div>
        <div
          css={css`
            width: 400px;
            text-align: center;
            @media (max-width: 950px) {
              width: 300px;
            }
            @media (max-width: 768px) {
              width: 90vw;
              margin-bottom: 20px;
            }
          `}
        >
          <p>Chicago, IL - Fort Myers, FL</p>
          <p>312.857.5486 - info@bowrenewables.com</p>
        </div>
      </div>
    </>
  );
};

export default React.memo(Footer);

import React from "react";
import { Global, css } from "@emotion/core";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";

import useSiteMetadata from "../hooks/use-sitemetadata";
import ParallaxBackground from "./parallax-background";

const Layout = ({ children }) => {
  const { title, description, keywords } = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          html,
          body {
            margin: 0;
            color: #555;
            font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
              sans-serif;
            font-size: 18px;
            line-height: 1.4;

            /* remove margin for main div that Gatsby mounts into */
            line-height > div {
              margin-top: 0;
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #1a4428;
            line-height: 1.1;
            font-family: "Reem Kufi";
            + * {
              margin-top: 0.5rem;
            }
          }

          p {
            font-family: Montserrat;
            color: #1a4428;
          }

          strong {
            color: #1a4428;
          }

          li {
            margin-top: 0.25rem;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Header />
      <main
        css={css`
          margin: 72px auto 0;
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          @media (max-width: 1025px) {
            margin: 60px auto 0;
          }
        `}
      >
        <ParallaxBackground />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;

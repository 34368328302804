import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const NavLink = styled(AnchorLink)`
  color: #215732;
  font-family: "Reem Kufi";
  font-size: 1.25rem;
  font-weight: ${(props) => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  text-shadow: 1px 1px 3px #c2c2c266;
`;

const NavMenu = styled("div")`
  width: 100vw;
  position: fixed;
  top: 60px;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(0.25turn, #ebf8e1, #f1a948);
  transition: height 0.5s ease;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const MobileNav = ({ height, setHeight }) => {
  return (
    <NavMenu
      css={css`
        height: ${height};
      `}
    >
      <NavLink
        to="/about"
        activeClassName="current-page"
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        WHY BOW
      </NavLink>
      <NavLink
        to="/gosolar"
        activeClassName="current-page"
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        GO SOLAR
      </NavLink>
      <NavLink
        to="/consulting"
        activeClassName="current-page"
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        CONSULTING
      </NavLink>
      <NavLink
        to="/customsolutions"
        activeClassName="current-page"
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        CUSTOM SOLUTIONS
      </NavLink>
      <NavLink
        to="/projects"
        activeClassName="current-page"
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        PROJECTS
      </NavLink>
      <br />
      <NavLink
        to="/about#contact"
        activeClassName="current-page"
        stripHash
        onClick={() => {
          setHeight("0px");
        }}
        onKeyDown={() => {
          setHeight("0px");
        }}
      >
        CONTACT
      </NavLink>
    </NavMenu>
  );
};

export default MobileNav;

import React from "react";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import { Parallax } from "react-scroll-parallax";

const ParallaxBackground = () => {
  const background = useStaticQuery(graphql`
    query Background {
      file(relativePath: {eq: "background.png" }) {
        image: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Parallax y={[-20, 20]} css={css`
        position: absolute;
        top: 0;
        z-index: -1;
      `}>
        <Image
          fadeIn={false}
          loading="eager"
          fluid={background.file.image.fluid}
          alt="Background Honeycomb Image with Parallax Scroll"
          css={css`
            width: 100vw;
            min-width: 1200px;
            opacity: .25;
          `}
          />
      </Parallax>
    </>
  )
}

export default ParallaxBackground;